<template>
  <div>
    <!-- banner contact  –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners-contact">
      <div class="container">
        <div class="row">
          <Navbar></navbar>

          <div class="section six columns banner-text">
            <h2 class="banners-heading"><translate>Contact</translate></h2>
            <p>── <translate>Et si on prenait un café ?</translate></p>
          </div>
        </div>
      </div>
    </div>

    <!-- contact –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="section contact">
      <div class="container">
        <div class="row">
          <div class="one-half column">
            <h4>ielo</h4>
            <p> ── </p>
            <div class="contact-title"><translate>Adresse</translate></div>
            <div class="contact-con">ielo</div>
            <div class="contact-con"><translate>50 Ter rue de Malte</translate></div>
            <div class="contact-con">75011 Paris</div>
            <div class="contact-title"><translate>Nous contacter:</translate></div>
            <div class="contact-con">+33 (0) 1 82 28 82 82</div>
            <div class="contact-con">info AT ielo.net</div>
            <div class="contact-title"><translate>Suivez-nous</translate></div>
            <div class="contact-con">
              <a href="https://twitter.com/ielo_group" target="_blank" rel="noopener">
                <img src="../assets/brands/twitter.png"/>
              </a>
              <a href="https://www.linkedin.com/company/liazo/" target="_blank" rel="noopener">
                <img src="../assets/brands/linkedin.png"/>
              </a>
            </div>
          </div>
          <div class="one-half column">
            <ContactForm default_routing_type="commercial"></ContactForm>
          </div>
        </div>
      </div>
    </div>

    <!-- map –––––––––––––––––––––––––––––––––––––––––––––––––– --><!--todo ajuster-->
    <div id="map" class ="temp"></div>

    <Newsletter></Newsletter>

    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Footer from '../components/Footer'
  import Newsletter from '../components/Newsletter'
  import ContactForm from '../components/ContactForm'

  // Openlayers
  import "ol/ol.css";
  import {Map, View} from "ol";
  import TileLayer from "ol/layer/Tile";
  import OSM from "ol/source/OSM";
  import layerVector from "ol/layer/Vector"
  import sourceVector from "ol/source/Vector"
  import Feature from "ol/Feature"
  import Point from "ol/geom/Point"
  import Style from "ol/style/Style"
  import Icon from "ol/style/Icon"
  import {fromLonLat} from 'ol/proj'
  import {defaults} from 'ol/interaction'

  export default {
    name: 'Contact',
    components: {Navbar, Newsletter, ContactForm, Footer},

    data() {
      return {
        map: null,
        marker:
          {
            lat: 48.865798,
            lon: 2.367769,
            properties: {
              name: "50 Ter rue de Malte"
            }
          }

      };
    },
    mounted() {
      this.$nextTick(function () {
        this.initMap();
      })
    },
    methods: {
      initMap() {
        new Map({
          target: 'map',
          interactions: defaults({mouseWheelZoom:false}),
          layers: [
            new TileLayer({
              source: new OSM({
                url: "https://portal.ielo-liazo.com/tiles/{z}/{x}/{y}.png"
              })
            }),
            new layerVector({
              source: new sourceVector({
                features: [new Feature({
                  geometry: new Point(fromLonLat([this.marker.lon, this.marker.lat]))
                })]
              }),
              style: new Style({
                image: new Icon(({
                  anchor: [0.5, 1],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  opacity: 1,
                  src: require('../assets/home-location-marker.png')
                }))
              })
            }),
          ],

          view: new View({
            projection: "EPSG:3857",
            center: [260573.82942416586, 6250829.080591002], //Paris
            zoom: 13
          })
        })
      }
    }
  }
</script>

<style>
</style>






