// Global settings

var PORTAL_BASE_URL = undefined

if (window.webpackHotUpdate) {
  PORTAL_BASE_URL = "http://127.0.0.1:8000"
} else {
  PORTAL_BASE_URL = "https://portal.ielo-liazo.com"
}

const settings = {
  PORTAL_BASE_URL: PORTAL_BASE_URL
}

export default settings