import Vue from 'vue'
import App from './App.vue'
import router from './router'

require('@/assets/styles/skeleton.css')
require('@/assets/styles/normalize.css')
require('@/assets/styles/custom.css')
require('@/assets/styles/font.css')

import GetTextPlugin from "vue-gettext";
import translations from '@/translations/en.json'
// import translations from '@/translations/fr.json'

// Import Element-UI components
import { Carousel, CarouselItem } from 'element-ui'
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { Collapse, CollapseItem } from 'element-ui'


Vue.config.productionTip = false

Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)

Vue.use(GetTextPlugin, {
  availableLanguages: {
    fr: 'Français',
    en: 'English'
  },
  defaultLanguage: 'fr',
  languageVmMixin: {
    methods: {
      merge (locale) {
        Object.assign(translations, locale);
      },
    }
  },
  translations: translations,
  silent: true,
})

new Vue({
  created () {
    const defaultLanguage = navigator.language.startsWith('en') ? 'en' : 'fr' || 'fr'
    const savedLanguage = window.localStorage.getItem('language')
    const language = savedLanguage === null ? defaultLanguage : savedLanguage

    if (!Vue.$translations.hasOwnProperty.call(language)) {
      import('@/translations/' + language+ '.json').then( (locale) => {
        this.$language.merge(locale);
        this.$language.current = language
      })
    }
  },
  router: router,
  render: h => h(App)
}).$mount('#app')
