<template>
  <div>
    <!-- banner equipe –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners-equipe">
      <div class="container">
        <div class="row">
          <Navbar page="team"></Navbar>
        </div>
      </div>
    </div>

    <!-- l'equipe de vos reves –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="section equipe">
      <div class="container">
        <div class="row">
          <div class="one-half column">
            <div class="img-wrapper">
              <img class="img-base" src="../assets/equipe-img.jpg">
            </div>
          </div>
          <div class="one-half column">
            <div class="equipe-text">
              <h4><span class="keywords"><translate>Qui </translate></span>
                <translate>sommes - nous?</translate>
              </h4>
              <p> ── </p>
              <p>
                <translate>ielo s’est construite au cours des 11 dernières années autour d’une aventure humaine portée
                  par une équipe d’entrepreneurs enthousiastes et passionnés.
                </translate>
              </p>
              <p>
                <translate>Travailler chez ielo, c’est rejoindre un projet ambitieux, construit et réaliste, valorisant
                  l’esprit d'initiative, le collectif et l’engagement.
                </translate>
              </p>
            </div>
            <div class="equipe-text">
              <h4><span class="keywords"><translate>Rejoignez </translate></span>
                <translate>- nous</translate>
              </h4>
              <p> ── </p>
              <p>
                <translate>Pour mener à bien son projet de croissance, ielo élargit ses équipes et recrute de façon très
                  active.</translate>
              </p>
              <p>
                <translate>Nous recrutons dans tous nos services, en particulier des techniciens et ingénieurs, en vue
                  de soutenir le déploiement de notre réseau, partout en France !
                </translate>
              </p>
              <p>
                <translate>A horizon 5 ans, le déploiement du réseau national se traduira par la création de nouveaux
                  centres techniques régionaux et le recrutement de 220 collaborateurs supplémentaires.
                </translate>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- rejoindre ielo –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="section equipe-rejoindre-ielo">
      <div class="container">
        <div class="row">
          <div class="one-half column">
            <h4>
              <translate>Recrutement </translate>
              <span class="keywords">ielo</span></h4>
            <p> ── <span><translate>Nos offres d’emploi</translate></span></p>
            <div class="block">
              <span class="demonstration"></span>
              <el-carousel height="300px" indicator-position="none" class="team">
                <el-carousel-item>
                  <h6><translate>Technicien Bureau d’étude - Paris</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Ingénieurs et techniciens déploiement - Paris et Régions</translate></h6>
                  <ul>
                    <li><translate>Ingénieurs et techniciens (Paris et région)</translate></li>
                    <li><translate>Chef de Travaux (région)</translate></li>
                    <li><translate>Ingénieur Avant-Ventes</translate></li>
                  </ul>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Responsable commercial - Paris</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Responsable méthodes et process comptables</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chef de projet - Paris</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chef d'Équipe Déploiement Fibre</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Raccordeur</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Assistant Raccordeur</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Tireur Câbleur</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chef Comptable</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Ingénieur Système & Réseau</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chef de Projet</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chargé de Déploiement Fibre</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chargé(e) d'études Déploiement Fibre(Paris + Région)</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chargé d'Études</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Technicien Support</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Office Manager</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Agent Delivery Support Junior</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Agent Livraison Commandes et Support(Paris)</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Responsable Sécurité Déploiement Fibre</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Chef de chantier Déploiement Fibre</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Développeur Full Stack</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Comptable Comptabilité Auxilaires</translate></h6>
                </el-carousel-item>
                <el-carousel-item>
                  <h6><translate>Adjoint Chef Comptable</translate></h6>
                </el-carousel-item>
              </el-carousel>
            </div>

            <p> ── <span><translate>Candidatures spontanées</translate></span></p>
            <h6>
              <translate>Envie de nous rejoindre? Envoyez vos candidatures à</translate>
              &nbsp;<a href="mailto:rh@ielo.net">rh&#64;ielo.net</a></h6>
          </div>

          <div class="one-half column">
            <ContactForm default_routing_type="job"></ContactForm>
          </div>
        </div>
      </div>
    </div>

    <!-- footer –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <Footer></Footer>
  </div>

</template>

<script>
  import Navbar from '../components/Navbar'
  import Footer from '../components/Footer'
  import ContactForm from '../components/ContactForm'

  export default {
    name: 'Equipe',
    components: {Navbar, Footer, ContactForm}
  }
</script>

<style>
  .banners-equipe .navbar {
    border-bottom: 1px solid #030D18;
  }

  .banners-equipe .navbar-link {
    color: #030D18;
  }

  .banners-equipe .router-link-active {
    color: #01B1AD;
  }

  .banners-equipe .navbar-link:hover {
    color: #01B1AD;
  }

  .team .el-carousel__arrow {
    top: 7%;
  }

  .team .el-carousel__arrow--left {
    left: 0px;
  }

  .team .el-carousel__arrow--right {
    right: 0px;
  }
  .el-carousel__item h6 {
    opacity: 0.75;
    text-align: left;
    word-wrap: break-word;
    padding: 5px 35px 0px 40px;
    margin-bottom: 0;
    color: #8C8B8B;
  }

  .el-carousel__item ul {
    padding: 0px 35px 0px 45px;
    color: #8C8B8B;
    font-size: 14px;
  }

  .el-carousel__item ul li {
    margin-bottom: 0;
  }
</style>






