<template>
  <div class="home-offres">
    <h2 class="home-offres-heading">
      <router-link :to="{name: 'offers'}"><translate>Voir nos Offres</translate></router-link>
    </h2>
    <div class="container">
      <div class="row">
        <el-carousel :height="height">
          <!--<el-carousel height=450px>-->
          <el-carousel-item>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/leboncoin.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/zayo.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/francetv.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/epsilon.png"></div>
            </div>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/gtt.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/adista.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/afnic.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/alsatis.png"></div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/voxity.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/blablacar.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/cdn77.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/bso.png"></div>
            </div>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/celeste.png">
              </div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/cirpack.png">
              </div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/aprr.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/dis.png"></div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/easter-eggs.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/efisns.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/evolix.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/fiducial-cloud.png"></div>
            </div>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/finsecur.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/gandi.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/intercloud.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/lasotel.png"></div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/naitways.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/nbs.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/oceanet.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/online.png"></div>
            </div>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/sdv.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/skyrock.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/smile.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/stella.png"></div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/transatel.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/vivaction.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/wallix.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/unyc.png"></div>
            </div>
            <div class="home-offres-logo">
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/maeva.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/sewan.png"></div>
              <div class="three columns"><img class="u-max-full-width" src="../assets/logos/uni-media.png"></div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "NotreOffres",
    data() {
      return {
        height: '450px',
        screen: '0px'
      }
    },
    mounted() {
      this.screen = window.innerWidth
      this.getSize()
      window.onresize = () => {
        this.screen = window.innerWidth
        this.getSize()
      }
    },
    methods: {
      getSize() {
        if (this.screen <= 470) {
          this.height = 650 + 'px'
        } else {
          this.height = 400 + 'px'
        }
      }
    }
  }
</script>

<style>
  .home-offres-heading a {
    color: #FFF;
    text-underline-offset: 0.3em;
  }

  .home-offres-heading a:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  .home-offres-heading {
    background: #1CB0AC;
    text-align: center;
    padding: 3rem 0 3rem;
    color: #fff;
    margin-bottom: 10rem;
  }
</style>
