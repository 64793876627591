<template>
  <div>
    <!-- banner offre –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners-offres">
      <div class="container">
        <div class="row">
          <Navbar></Navbar>

          <div class="section six columns banner-text">
            <h2 class="banners-heading"><translate>Très haut débit garanti</translate></h2>
            <p>── <translate>Tous vos besoins en infrastructure opérateur</translate></p>

            <BreakingNews></BreakingNews>
          </div>
        </div>
      </div>
    </div>

    <!-- nos offres –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="section offre-text">
      <div class="container">
        <div class="row">
          <div class="one-half column">
            <h2><translate>Nos offres</translate></h2>
            <h6><translate>Grâce à son large portefeuille de services opérateurs, ielo apporte une solution à chacun de vos besoins en matière d’infrastructure télécoms.</translate></h6>
            <h6><translate>Son réseau de dernière génération permet de disposer d’une performance inégalée et d’une couverture unique sur l’ensemble territoire.</translate></h6>
            <h6><translate>Assurant le déploiement et la maintenance des services en interne, ielo vous accompagne dans vos projets avec une totale maîtrise de la chaine de production et d’exploitation.</translate></h6>
            <h6><translate>Le portail client permet de disposer en temps réel d’un accès à toutes vos opérations : éligibilité, tarifs, commande, suivi de livraison et support (Web et APIs).</translate></h6>
            <el-collapse v-model="activeName">
              <el-collapse-item name="1">
                <template slot="title">
                  <span class="offer-title-collapse"><translate>#01 Collecte</translate></span>
                </template>
                <h5><translate>Porte de collecte multi-opérateur et multi-services</translate></h5>
                <p> - <translate>FTTO - Fibre optique dédiée (jusqu’à 10 Gbps)</translate></p>
                <p> - <translate>FTTH</translate></p>
                <p> - <translate>SDSL</translate></p>
                <p> - <translate>ADSL/VDSL</translate></p>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template slot="title">
                  <span class="offer-title-collapse"><translate>#02 Transport & Accès</translate></span>
                </template>
                <p><translate>Service E-line – Carrier Ethernet Service</translate></p>
                <p><translate>Service Wave – longueur d’onde dédiée</translate></p>
                <p><translate>Fibre optique noire – en location ou IRU</translate></p>
              </el-collapse-item>
              <el-collapse-item name="3">
                <template slot="title">
                  <span class="offer-title-collapse"><translate>#03 Transit IP</translate></span>
                </template>
                <h5><translate>Offre de transit IP (à partir de 100Mbps)</translate></h5>
                <p> - <translate>Réseau BGP multi-opérateurs IPv4/IPv6 natif</translate></p>
                <p> - <translate>1700+ Peerings: Majorité du trafic routé via nos Peerings (vs Transitaires)</translate></p>
                <p> - <translate>Routage naturel – Transparence aux communautés</translate></p>
                <p> - <translate>Option Protection anti DDOS</translate></p>
              </el-collapse-item>
              <el-collapse-item name="4">
                <template slot="title">
                  <span class="offer-title-collapse"><translate>#04 Colocation</translate></span>
                </template>
                <h5><translate>Offres d’hébergement disponibles du simple U à la baie</translate></h5>
                <p> - <translate>Plus de 100 points de présence dont la majorité des datacenters français et des campus européens</translate></p>
                <p> - <translate>Accès à plus de 150 opérateurs - Latence minimale</translate></p>
                <p> - <translate>Haute disponibilité garantie (TIER 3-4)</translate></p>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="one-half column">
            <div class="img-wrapper"><img class="img-base" src="../assets/Visu_Offre1.jpg"></div>
          </div>
        </div>
      </div>
    </div>

    <Newsletter></Newsletter>

    <!-- footer –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Footer from '../components/Footer'
  import BreakingNews from '../components/BreakingNews'
  import Newsletter from '../components/Newsletter'

  export default {
    name: 'Offers',
    components: {Navbar, BreakingNews, Newsletter, Footer},
    data () {
      return {
        activeName: ['1'],
      };
    }
  }
</script>

<style>
  .offer-title-collapse span {
    font-size: 20px;
    font-family: "Roboto-Bold";
  }
</style>






