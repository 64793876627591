<template>
  <div class="section honeycomb newsletter">
    <div class="container">
      <form @submit="subscribe">
        <input v-model="email" type="email" placeholder="Email..." :disabled="subscribed === 2">
        <button v-if="subscribed === 0" type="submit" class="newsletter-submit">
          <i class="el-icon-message"></i> <translate>Valider</translate>
        </button>
        <div v-else-if="subscribed === 1"><h4><i class="el-icon-loading"></i></h4></div>
        <div v-else><h4>Ok !</h4></div>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import settings from '../settings.js'

  export default {
    name: "Newsletter",
    data: function() {
      return {
        subscribed: 0,
        email: ""
      }
    },
    methods: {
      subscribe (ev) {
        ev.preventDefault()
        if (this.email === "") {
          return false;
        }

        this.subscribed = 1

        axios.post(`${settings.PORTAL_BASE_URL}/public/newsletter/subscribe`, {email: this.email})
          .then(() => {
            this.subscribed = 2
          })
          .catch(() => {
            this.subscribed = 0
          })

        return false
      }
    }
  }
</script>

<style scoped>
  .newsletter-submit {
    border: none;
    margin-top: 5px;
  }
</style>