<template>
  <div class="section honeycomb number">
    <div class="twelve columns">
      <div class="four columns">
        <h5 class="number-heading"><translate>Km de câbles</translate></h5>
      </div>
      <div class="four columns">
        <h5 class="number-heading"><translate>Agglomérations</translate></h5>
      </div>
      <div class="four columns">
        <h5 class="number-heading"><translate>Communes</translate></h5>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Number"
  }
</script>

<style scoped>
  .number {
    background-image: url('../assets/bg-number.png');
  }

  .number h5 {
    line-height: 1.05;
    font-weight: 700;
    text-align: center;
    padding-left: 30px;
  }
</style>
