<template>
  <div class="breaking-news">
    <h6>
      <span>
        <translate>ielo poursuit sa dynamique de croissance avec une levée de fonds bancaire de 208M€ !</translate>
      </span>
    </h6>
    <a v-if="language === 'fr'" target="_blank" rel="noopener"  href="static/pr/pr-24-06-2024.pdf"><translate>En savoir plus</translate> <i class="el-icon-arrow-right"></i></a>
    <a v-else-if="language === 'en'" target="_blank" rel="noopener"  href="static/pr/pr-en-24-06-2024.pdf"><translate>En savoir plus</translate> <i class="el-icon-arrow-right"></i></a>
  </div>
</template>

<script>
  export default {
    name: "BreakingNews",
    data: function() {
      return {
        language: window.localStorage.getItem('language') || 'fr'
      }
    }
  }
</script>
