import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home'
import News from './views/News'
import About from './views/About'
import Offers from './views/Offers'
import Team from "./views/Team"
import Contact from "./views/Contact"
import Network from "./views/Network"
import Community from "./views/Community"

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    {path: `${process.env.BASE_URL}home`, component: Home, name: 'home'},
    {path: `${process.env.BASE_URL}news`, component: News, name: 'news'},
    {path: `${process.env.BASE_URL}about`, component: About, name: 'about'},
    {path: `${process.env.BASE_URL}offers`, component: Offers, name: 'offers'},
    {path: `${process.env.BASE_URL}team`, component: Team, name: 'team'},
    {path: `${process.env.BASE_URL}contact`, component: Contact, name: 'contact'},
    {path: `${process.env.BASE_URL}network`, component: Network, name: 'network'},
    {path: `${process.env.BASE_URL}community`, component: Community, name: 'community'},
    {path: `${process.env.BASE_URL}*`, component: Home}
  ],
  // Scroll to top
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router

