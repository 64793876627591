<template>
  <div>
    <!-- Banner accueil –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners">
      <div class="container">
        <div class="row">
          <Navbar></Navbar>

          <div class="section seven columns banner-text">
            <h2 class="banners-heading">Fiber that matters</h2>
            <p>──
              <translate>Votre opérateur d’infrastructure neutre et dédié aux professionnels des télécoms</translate>
            </p>
            <BreakingNews></BreakingNews>
          </div>
        </div>
      </div>
    </div>

    <!-- ielo –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="section visu-home">
      <div class="container">
        <div class="row">
          <div class="one-half column">
            <div class="home-first-text left-side">
              <h3 class="one-half-h4">
                <translate>ielo apporte l’infrastructure indispensable au</translate>
                <span class="keywords"><translate>développement numérique</translate></span>
                <translate> des entreprises</translate>
              </h3>
            </div>
            <img src="../assets/Visu_Home1.jpg"/>
          </div>
          <div class="one-half column">
            <img src="../assets/Visu_Home2.jpg"/>
            <div class="home-first-text right-side">
              <h4 class="one-half-h5">
                <translate>ielo donne accès au très haut débit</translate>
              </h4>
              <p class="light-text">
                <translate>À travers son réseau urbain de dernière génération, ielo apporte à ses partenaires une
                  performance
                  optimale au meilleur coût.
                </translate>
              </p>
              <p class="light-text">
                <translate>Déployé selon des procédés maîtrisés et conçus en interne, il représente aujourd’hui plus de
                  21000 km de câbles couvrant plus de 120 agglomérations et permet à ses clients de connecter près de 3000
                  communes.
                </translate>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- reseau_number –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <Number></Number>

    <!-- notre reseau –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="notre-reseau">
      <NetworkDescription id="network" :notice="false"></NetworkDescription>
      <NetworkCarte></NetworkCarte>
    </div>

    <NosOffres></NosOffres>
    <Newsletter></Newsletter>

    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import NetworkDescription from '../components/NetworkDescription'
  import NetworkCarte from '../components/NetworkCarte'
  import NosOffres from '../components/OurOffer'
  import Number from '../components/Number'
  import Footer from '../components/Footer'
  import BreakingNews from '../components/BreakingNews'
  import Newsletter from '../components/Newsletter'

  export default {
    name: 'Home',
    components: {
      Navbar, NetworkDescription, NetworkCarte, NosOffres, Number, BreakingNews, Newsletter, Footer
    },
  }
</script>

<style>
  .visu-home .left-side {
    font-family: "Roboto-Bold";
    margin-bottom: 30px;
  }

  .visu-home .right-side {
    margin-top: 20px;
  }

  .visu-home img {
    background-size: cover;
    width: 100%;
  }

  .home-first-text {
    text-align: left;
  }
</style>
