<template>
  <div>
    <!-- Banner community(home) –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners-community">
      <div class="container">
        <div class="row">
          <Navbar></Navbar>

          <div class="section seven columns banner-text">
            <div class="community-banners-heading">
              <h2><translate>Partenaire des collectivités</translate></h2>
              <p>── <translate>Ensemble, accélérons l’attractivité numérique de vos territoires</translate></p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="twelve columns">
          <p  v-if="language === 'fr'" class="img-wrapper network-map-wrapper"><img class="img-base" src="../assets/map_reseau_ielo_seule_fr.png"></p>
          <p v-else-if="language === 'en'" class="img-wrapper network-map-wrapper"><img class="img-base" src="../assets/map_reseau_ielo_seule_en.png"></p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <p class="community-except-p">
          <span class="keywords">
            <translate>Avec l’explosion des usages liés au développement du numérique et de la Smart City, les
              collectivités ont plus que jamais besoin de disposer de leurs propres infrastructures
            </translate>
          </span>
          <translate>afin de soutenir leurs missions de services publics tout en ouvrant la voie à
            l’innovation : Internet des Objets, intelligence artificielle, temps réel...</translate>
        </p>
      </div>

      <div class="row">
        <div class="twelve columns">
          <div class="img-wrapper community-carte-map-wrapper">
            <img class="img-base" src="../assets/community_carte_france_usages.png">
          </div>
        </div>
      </div>

      <div class="row">
        <p>
          <span class="keywords">
            <translate>Opérateur français d’infrastructures Très Haut Débit, ielo accompagne les Collectivités
              dans le déploiement de leurs propres réseaux numériques.
            </translate>
            </span>
          <translate>Se concentrant sur l’infrastructure télécom brute, ielo reste le partenaire privilégié des
            opérateurs pour apporter aux collectivités une gamme complète de services à valeur ajoutée.</translate>
        </p>
      </div>

      <div class="row">
        <p>
          <span class="keywords">
            <translate>Doté de son propre réseau Très Haut Débit de fibre optique, neutre et indépendant, ielo est
              présent dans plus de 120 agglomérations et 3 300 communes en France.
            </translate>
            </span>
          <translate>Ce réseau est maîtrisé de bout en bout, de la conception à l’exploitation par ses équipes présentes
            sur tout le territoire national autour de 12 Centres Techniques Régionaux. Depuis 2016, ielo accélère la
            numérisation des entreprises françaises, aux côtés de ses partenaires opérateurs. De plus en plus associé à
            l’action publique, ielo s’appuie sur ses infrastructures de dernière génération et l’expertise de ses 350
            collaborateurs pour faciliter la construction de nouveaux réseaux multi-usages, performants, sécurisés et
            indépendants pour les collectivités.
          </translate>
        </p>
      </div>

      <div class="row">
        <p>
          <span class="keywords">
            <translate>Pour répondre aux enjeux de connectivité des territoires, ielo met à disposition son
              savoir-faire : </translate>
            </span>
        </p>
      </div>
      <div class="row">
        <p>
          <span class="keywords">
            <translate>── Fibre Optique Noire</translate>
          </span>
        </p>
        <p>
          <translate>Métropolitaine et longue distance</translate>
        </p>
      </div>
      <div class="row">
        <p>
          <span class="keywords">
            <translate>── FTTO</translate>
          </span>
        </p>
      </div>
      <div class="row">
        <p>
          <span class="keywords">
            <translate>── Lan-to-Lan</translate>
          </span>
        </p>
      </div>
      <div class="row">
        <p>
          <span class="keywords">
            <translate>── Transit IP </translate>
          </span>
        </p>
      </div>
      <div class="row">
        <p>
          <span class="keywords">
            <translate>── Colocation</translate>
          </span>
        </p>
      </div>

      <div class="row">
        <p>
          <translate>A noter : la gamme d’offres de transport de Ielo – Fibre Optique Noire, LAN-to-LAN, Wave – est
            particulièrement adaptée aux collectivités.</translate>
        </p>
        <p>
          <translate>Elle permet la constitution d’un réseau d’interconnexion passive ou active d’équipements publics
            au service des nouveaux usages de la ville intelligente. Les collectivités gardent ainsi la maitrise de
            leurs réseaux tout en ayant la garantie des meilleurs niveaux de performance et de disponibilité du
            marché.</translate>
        </p>
      </div>

      <row>
        <h2 class="community-partner-heading">
          <translate>Ils nous font confiance</translate>
        </h2>
      </row>
      <row>
        <div class="community-partner-logo">
          <div class="four columns"><img class="u-max-full-width" src="../assets/logos/agirc-arrco.png"></div>
          <div class="four columns"><img class="u-max-full-width" src="../assets/logos/nouvelle_aquitaine.png"></div>
          <div class="four columns"><img class="u-max-full-width" src="../assets/logos/france_tv.png"></div>
        </div>
        <div class="community-partner-logo">
          <div class="four columns"><img class="u-max-full-width" src="../assets/logos/renater.png"></div>
          <div class="four columns"><img class="u-max-full-width" src="../assets/logos/universite_nantes_angers_le_mans.png"></div>
          <div class="four columns"><img class="u-max-full-width" src="../assets/logos/sorbonne_universite.png"></div>
        </div>
      </row>
    </div>



    <!-- footer –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Footer from '../components/Footer'

  export default {
    name: 'Propos',
    components: {Navbar, Footer},
    data() {
      return {
        activeName: ['1']
      };
    }
  }
</script>

<style>
  .news-right-side p {
    color: #B0B0B0;
    font-size: 15px;
  }

</style>






