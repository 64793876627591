<template>
  <el-dropdown @command="handleCommand">
    <p class="el-dropdown-link">
      <i class="el-icon-s-flag"></i>
      <translate>Langue</translate>
    </p>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="fr">🇫🇷 Français</el-dropdown-item>
      <el-dropdown-item command="en">🇬🇧 English</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script type="es6">

  export default {
    name: 'language-dropdown',
    inject:['reload'],
    props: ['value'],
    methods: {
      handleCommand (locale) {
        window.localStorage.setItem('language', locale)
        this.$language.current = locale
        this.reload()
      }
    }
  }
</script>

<style>
  .el-dropdown-link {
    cursor: pointer;
    font-size: 12px;
  }
</style>
