<template>
  <div>
    <!-- banner propos  –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners-propos">
      <div class="container">
        <div class="row">
          <Navbar></Navbar>
          <div class="section seven columns banner-text">
            <h2 class="banners-heading">
              <translate>ielo change d’échelle</translate>
            </h2>
            <p>──
              <translate>Votre opérateur d’infrastructure neutre et dédié aux professionnels des télécoms</translate>
            </p>
            <BreakingNews></BreakingNews>
          </div>
        </div>
      </div>
    </div>

    <!-- le texte  –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="about">
      <div class="about-panel">
        <div class="container">
          <div class="row">
            <div class="four columns">
              <div class="img-wrapper">
                <img class="about-img-base" src="../assets/Visu_Apropos1.jpg">
              </div>
            </div>
            <div class="eight columns">
              <h3>
                <translate>Projet de croissance:</translate>
                <span class="keywords"><translate> couvrir l’ensemble du territoire</translate></span>
              </h3>
              <p>
                <translate>ielo poursuit son développement sur le territoire français avec près de </translate>
                <span class="keywords2"><translate>160 millions d’euros d’investissements</translate></span>
                <translate> sur les cinq prochaines années.</translate>
              </p>
              <p>
                <translate>Le réseau fibre de ielo est le réseau optique urbain le plus moderne et qualitatif sur le
                  territoire français, déployé selon des procédés maitrisés
                  et conçus en interne, marque de fabrique du groupe. Il représente aujourd’hui plus de 21 000 km de
                  câbles couvrant 120 agglomérations et permet à ses
                  clients partenaires de connecter près de 5 000 entreprises.
                </translate>
              </p>
              <p>
                <translate>Grâce au soutien du fonds européen DIF Capital Partners depuis 2020, ielo a réussi en deux ans à </translate>
                <span class="keywords2"><translate>tripler l’empreinte de son réseau structurant. </translate></span>
                <translate>ielo prévoit de continuer d'étendre sa présence au niveau national et de proposer des services
                   à très haut débit à ses partenaires.</translate></p>
              <p>
                <translate>Ce socle d’infrastructure fibre, détenu, déployé et opéré en propre permettra au groupe ielo
                  de commercialiser de nouveaux produits maîtrisés à forte
                  valeur ajoutée, couvrant dès lors, les besoins de toutes les entreprises et venant compléter ainsi son
                  offre phare FTTO.
                </translate>
              </p>
              <p>
                <translate>Pour mener à bien ce projet ambitieux, ielo a doublé ses équipes et a créé 12 Centres Techniques
                  Régionaux pour opérer son réseau au plus près. Début 2023, ielo compte 330 salariés et compte recruter de
                  nombreux nouveaux talents.
                </translate>
              </p>

            </div>
          </div>
        </div>
      </div>
      <div class="about-panel">
        <div class="container">
          <div class="row">
            <div class="eight columns">
              <h3>
                <translate>La success story</translate>
                <span class="keywords"> ielo</span></h3>
              <p>
                <translate>La success story ielo est née en 2016 du rapprochement entre de jeunes entrepreneurs français
                  et de leurs expertises réseau - en matière de transport et collecte d’une
                  part et transit hébergement d’autre part. En 4 ans, la nouvelle entité ielo-liazo a connu une très
                  forte croissance et a vu sa zone de couverture passer de 4 à 120
                  agglomérations et de 10 à 3000 communes.
                </translate>
              </p>
              <p>
                <translate>Revendiquant son statut d’opérateur neutre sur le marché dit de gros (« wholesale »), le
                  groupe a réalisé en 2022 un chiffre d’affaires de l’ordre de 33 millions d’euros.
                  Nous sommes à ce jour plus de 300 collaborateurs et nous comptons plus de 300 clients.
                </translate>
              </p>
            </div>
            <div class="four columns">
              <div class="row offres-testimonials">
                <div class="twelves columns">
                  <p>
                    <i>
                      <translate>
                        « Notre rôle est de créer et d’opérer le socle d’infrastructure nécessaire
                        à nos clients et partenaires, afin que ceux-ci puissent répondre de manière
                        qualitative à la demande croissante du marché Entreprises. Nous allons poursuivre
                        et accélérer, à grande échelle, le développement de notre infrastructure fibre
                        optique, tout en maintenant et en améliorant ce qui a fait le succès de ielo :
                        son indépendance, son éthique et son savoir-faire »
                      </translate>
                    </i>
                  </p>
                  <div class="name">Arthur Fernandez</div>
                  <i>
                    <translate>Président de ielo</translate>
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-panel">
        <div class="container">
          <div class="row">
            <div class="five columns">
              <div class="row offres-testimonials">
                <div class="twelves columns">
                  <p>
                    <i>
                      <translate>
                        « DIF Capital Partners investit largement depuis plusieurs années
                        dans le secteur des télécommunications en Europe et en Amérique du
                        Nord, notamment dans des projets liés aux data centers et à la fibre optique.
                        Cette prise de participation s’inscrit donc dans notre stratégie
                        d’investissement sur le marché à fort potentiel des infrastructures numériques,
                        en particulier dans le marché en pleine croissance du B2B. Nous avions
                        identifié IELO, un des rares acteurs dédiés aux infrastructures wholesale
                        et le seul à avoir la volonté de s’étendre nationalement, comme le partenaire idéal. »
                      </translate>
                    </i>
                  </p>
                  <div class="name">Thomas Vieillescazes</div>
                  <i>
                    <translate>Associé et responsable France de DIF Capital Partners</translate>
                  </i>
                </div>
              </div>
            </div>
            <div class="seven columns">
              <h3>DIF <span class="keywords">Capital Partners</span></h3>
              <p>
                <translate>DIF Capital Partners est un fonds d’infrastructure indépendant européen disposant de plus de
                  6,7 milliards d’euros d’actifs positionné sur des projets d’infrastructures long-terme.
                </translate>
              </p>
              <p>
                <translate>Le groupe compte plus de 135 personnes répartis dans ses 9 bureaux à Amsterdam (Schiphol),
                  Francfort, Londres, Luxembourg, Madrid, Paris, Santiago, Sidney et Toronto.
                </translate>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Newsletter></Newsletter>

    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Footer from '../components/Footer'
  import BreakingNews from '../components/BreakingNews'
  import Newsletter from '../components/Newsletter'

  export default {
    name: 'Propos',
    components: {Navbar, Footer, BreakingNews, Newsletter},
    data() {
      return {
        activeName: ['1'],
        activeNameInfrastructure: ['1']
      };
    }
  }
</script>

<style>
  .about {
    padding: 5rem 0 0em;
  }

  .about-panel {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .about-panel:nth-child(even) {
    background-color: #F1F0EF;
  }
</style>
