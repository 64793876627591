<template>
  <div>
    <!-- banner news  –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners-equipe">
      <div class="container">
        <div class="row">
          <Navbar page="news"></Navbar>
        </div>
      </div>
    </div>

    <!-- notre infrastructure –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="section news">
      <div class="container">
        <div class="row">
          <div class="one-half column">
            <div class="img-wrapper"><img class="img-base" src="../assets/Visu_Apropos2.jpg"></div>
          </div>
          <div class="one-half column">
            <div class="news-right-side">
              <h4 class="news-right-side-header">
                <translate>Notre projet de</translate> <span class="keywords"><translate>croissance</translate></span>
              </h4>
              <p> ── </p>
              <el-collapse v-model="activeName">
                <el-collapse-item name="1">
                  <template slot="title">
                    <h5>#01</h5>
                  </template>
                  <p><translate>ielo poursuit sa dynamique de croissance avec une levée de fonds bancaire de 208M€ !</translate></p>
                  <p>
                    <a v-if="language === 'fr'" target="_blank" rel="noopener" href="static/pr/pr-24-06-2024.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                    <a v-else-if="language === 'en'" target="_blank" rel="noopener" href="static/pr/pr-en-24-06-2024.pdf" class="button button-primary">
                      <translate>read the press release</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                    <img class="fiber-icon" src="../assets/propos-icon.png"/>
                  </p>
                </el-collapse-item>
                <el-collapse-item name="2">
                  <template slot="title">
                    <h5>#02</h5>
                  </template>
                  <p><translate>Bilan 2023 et perspectives 2024: la fibre ielo gagne du terrain!</translate></p>
                  <p>
                    <a v-if="language === 'fr'" target="_blank" rel="noopener" href="static/pr/pr-31-01-2024.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                    <a v-else-if="language === 'en'" target="_blank" rel="noopener" href="static/pr/pr-en-31-01-2024.pdf" class="button button-primary">
                      <translate>read the press release</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                    <img class="fiber-icon" src="../assets/propos-icon.png"/>
                  </p>
                </el-collapse-item>
                <el-collapse-item name="3">
                  <template slot="title">
                    <h5>#03</h5>
                  </template>
                  <p><translate>ielo, opérateur neutre et indépendant d’infrastructures nationales Très Haut Débit pour les entreprises, annonce l’acquisition de Quantic Telecom, opérateur normand de fibre optique dédiée. ielo consolide ainsi sa présence sur le territoire en unifiant les réseaux FttO et les savoir-faire des deux entités.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-01-06-2023.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>

                <el-collapse-item name="4">
                  <template slot="title">
                    <h5>#04</h5>
                  </template>
                  <p><translate>Grâce au déploiement de son nouveau backbone national très haut débit, ielo débride la connectivité pour les entreprises jusqu'à 10Gbps.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-14-02-2023.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>

                <el-collapse-item name="5">
                  <template slot="title">
                    <h5>#05</h5>
                  </template>
                  <p><translate>IELO, l’opérateur d’infrastructures télécoms français, poursuit sa stratégie de croissance et annonce l’ouverture de son réseau de transmission optique national. Ce réseau long de 10,000 kilomètres, interconnecte plus de 200 nœuds optiques WDM qui permettent de connecter à très haut débit les entreprises et datacenters français.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-05-07-2021.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>

                <el-collapse-item name="6">
                  <template slot="title">
                    <h5>#06</h5>
                  </template>
                  <p><translate>France-IX, l'un des principaux points d'échange Internet (IXP) d'Europe, a choisi IELO,
                    opérateur d’infrastructures fibre optique à destination du marché wholesale entreprise,
                    pour raccorder la majorité de ses points de présence (PoPs) via son réseau de fibre optique.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-08-02-2021.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>

                <el-collapse-item name="7">
                  <template slot="title">
                    <h5>#07</h5>
                  </template>
                  <p><translate>IELO, opérateur d’infrastructures fibre optique à destination du marché wholesale
                    entreprise, a déployé et opère son réseau métropolitain sur Tours et les villes avoisinantes
                    depuis plusieurs années. En raccordant le datacenter Castle IT, IELO poursuit son développement
                    qui s’inscrit dans sa volonté d’accompagner les acteurs de proximité dans les plus grandes
                    agglomérations françaises.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-23-11-2020.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>
                <el-collapse-item name="8">
                  <template slot="title">
                    <h5>#08</h5>
                  </template>
                  <p><translate>En raccordant le DC5 de Scaleway sur une deuxième route fibre optique, IELO renforce sa
                    présence sur le site de Saint-Ouen-l’Aumône en tant qu’opérateur neutre d’infrastructure pour mieux
                    répondre à la forte croissance de ce datacenter.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-09-11-2020.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>
                <el-collapse-item name="9">
                  <template slot="title">
                    <h5>#09</h5>
                  </template>
                  <p><translate>IELO annonce l’acquisition de l’infrastructure fibre optique de Neuronnexion, opérateur de proximité,
                    dans les Hauts-de-France. Cette acquisition entre dans le cadre de la stratégie de croissance d’IELO qui prévoit
                    d’investir 90 millions d’euros pour couvrir 95 métropoles françaises d’ici 2 ans.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-12-10-2020.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>
                <el-collapse-item name="10">
                  <template slot="title">
                    <h5>#10</h5>
                  </template>
                  <p><translate>ielo accueille un nouvel actionnaire et entend investir 160 millions d’euros dans l’accélération du
                    déploiement de son infrastructure
                    de fibre optique et l’élargissement de sa couverture dans les principales métropoles et zones
                    économiques de l’hexagone.</translate></p>
                  <p>
                    <a target="_blank" rel="noopener" href="static/pr/pr-21-07-2020.pdf" class="button button-primary">
                      <translate>lire le communiqué de presse</translate> <i class="el-icon-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>
                <el-collapse-item name="11">
                  <template slot="title">
                    <h5>#11</h5>
                  </template>
                  <p>
                    <translate>Télécoms: Ielo réalise une levée de fonds pour devenir numéro 3 du marché entreprises </translate>
                    <a target="_blank" rel="noopener" href="https://www.lefigaro.fr/flash-eco/telecoms-ielo-realise-une-levee-de-fonds-pour-devenir-numero-3-du-marche-entreprises-20200721" >
                      <translate>Le Figaro</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>
                  <p>
                    <translate>Ielo s’allie à DIF Capital Partners pour son réseau de fibre optique destiné aux entreprises </translate>
                    <a target="_blank" rel="noopener" href="https://capitalfinance.lesechos.fr/deals/capital-developpement/ielo-sallie-a-dif-capital-partners-pour-son-reseau-de-fibre-optique-destine-aux-entreprises-1225219">
                      <translate>Les Echos</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>

                  <p>
                    <translate>Ielo investit 160 millions d’euros en cinq ans dans son réseau optique </translate>
                    <a target="_blank" rel="noopener" href="https://www.usinenouvelle.com/article/ielo-investit-160-millions-d-euros-en-cinq-ans-dans-son-reseau-optique.N987374">
                      <translate>Usine Nouvelle</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>
                  <p>
                    <translate>IELO renforce sa présence sur le datacenter Scaleway DC5/95 (Val d’Oise) </translate>
                    <a target="_blank" rel="noopener" href="https://www.globalsecuritymag.fr/IELO-renforce-sa-presence-sur-le,20201110,104761.html">
                      <translate>Global Security Mag</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>

                  <p>
                    <translate>IELO renforce sa présence sur le datacenter Scaleway DC5/95 (Val d’Oise) </translate>
                    <a target="_blank" rel="noopener" href="http://www.mtom-mag.com/article11495.html">
                      <translate>MtoM Mag</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>
                  <p>
                    <translate>IELO reprend les réseaux fibre Amiens et St Quentin de Neuronnexion </translate>
                    <a target="_blank" rel="noopener" href="https://www.distributique.com/actualites/lire-ielo-reprend-les-reseaux-fibre-amiens-et-st-quentin-de-neuronnexion-30647.html">
                      <translate>Distributique</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>

                  <p>
                    <translate>Fibre optique : Ielo rachète les réseaux de Neuronnexion à Amiens et Saint-Quentin </translate>
                    <a target="_blank" rel="noopener" href="https://www.nextinpact.com/lebrief/44152/fibre-optique-ielo-rachete-reseaux-neuronnexion-a-amiens-et-saint-quentin">
                      <translate>Next INpact</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>
                  <p>
                    <translate>Ielo sous pavillon DIF relie les Hauts-de-France </translate>
                    <a target="_blank" rel="noopener" href="https://www.cfnewsimmo.net/L-actualite/Infrastructure/Capital-developpement/Ielo-sous-pavillon-DIF-relie-les-Hauts-de-France">
                      <translate>CFNEWS</translate> <i class="el-icon-d-arrow-right"></i>
                    </a>
                  </p>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Newsletter></Newsletter>

    <!-- footer –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Footer from '../components/Footer'
  import Newsletter from '../components/Newsletter'

  export default {
    name: 'Propos',
    components: {Navbar, Footer, Newsletter},
    data() {
      return {
        activeName: ['1', '2', '3', '4', '5'],
        language: window.localStorage.getItem('language') || 'fr'
      };
    }
  }
</script>

<style>
  .news-right-side {
    /*padding-top: 25px;*/
    margin-left: 57px; /*todo*/
  }

  .news-right-side-header {
    font-size: 30px;
    line-height: 1.05;
    font-weight: 700;
  }

  .news-right-side p {
    color: #B0B0B0;
    font-size: 15px;
  }

  .fiber-icon {
    float: right;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }
</style>






