<template>
    <div class="container">
      <div class="row">
        <div class="twelve columns">
          <p  v-if="language === 'fr'" class="img-wrapper network-map-wrapper"><img class="img-base" src="../assets/map_reseau_ielo_seule_fr.png"></p>
          <p v-else-if="language === 'en'" class="img-wrapper network-map-wrapper"><img class="img-base" src="../assets/map_reseau_ielo_seule_en.png"></p>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    props: ['notice'],
    name: 'OurNetwork',
    data: function() {
      return {
        language: window.localStorage.getItem('language') || 'fr'
      }
    }
  }
</script>

<style>
</style>






