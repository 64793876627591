<template>
  <div class="notre-reseau-description">
    <div class="container">
      <div class="row">
        <div class="seven columns">
          <h3><translate>Notre </translate><span class="keywords"><translate>Réseau fibre</translate></span></h3>
          <p> ── <translate>ielo dispose d’une zone de couverture étendue sur 120 agglomérations, soit 3000 communes.</translate></p>
          <p>
            <translate>Dans le cadre de son projet de développement, ielo a finalisé fin 2022 le déploiement d’un réseau
              national de fibre optique de plus de 21 000 km. </translate>
          </p>
        </div>
        <div class="five columns notice" v-if="notice">
          <div class="notice-border">
            <p><translate>Réseau </translate><span class="keywords"><translate> dernière génération</translate></span></p>
            <p><translate>Présence au sein de plus de </translate><span class="keywords"><translate>100 Datacenters</translate></span></p>
            <p><span class="keywords"><translate>120 agglomérations</translate></span></p>
            <p><translate>12 Centres Techniques Régionaux</translate> <span class="keywords">(CTR)</span></p>
          </div>
          <div class="notice-border">
            <p><translate>Portail utilisateur </translate><span class="keywords"> <translate>intuitif</translate></span></p>
            <p><translate>Éligibilités et commandes instantanées</translate></p>
            <p><translate>Études, déploiements et interventions </translate><span class="keywords"> <translate>internalisées</translate></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['notice'],
    name: 'OurNetwork'
  }
</script>

<style>
  .notre-reseau-description {
    margin-bottom: 5rem;
    padding-top: 5rem;
    text-align: left;
  }
</style>






