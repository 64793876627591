<template>
  <section class="footer">
    <div class="container">
      <div class="row">
        <div class="value-props row">
          <div class="three columns footer-logo">
            <img class="img-base" src="../assets/logo.png">
          </div>
          <div class="three columns footer-contact">
            <h4>Contact</h4>
            <div class="contact-title"><translate>Adresse</translate></div>
            <div class="contact-con">50 Ter rue de Malte</div>
            <div class="contact-con">75011 Paris</div>
            <div class="contact-title"><translate>Téléphone</translate></div>
            <div class="contact-con">+33 (0) 1 82 28 82 82</div>
            <div class="contact-title"><translate>Mail</translate></div>
            <div class="contact-con">info&#64;ielo.net</div>
          </div>
          <div class="two columns footer-infos">
            <h4>Infos</h4>
            <p><router-link :to="{name: 'news'}"><translate>News</translate></router-link></p>
            <p><router-link :to="{path: 'network'}"><translate>Notre réseau</translate></router-link></p>
            <p><router-link :to="{name: 'offers'}"><translate>Nos offres opérateurs</translate></router-link></p>
            <p><router-link :to="{path: 'community'}"><translate>Collectivité</translate></router-link></p>
            <p><router-link :to="{name: 'about'}"><translate>À propos</translate></router-link></p>
            <p><router-link :to="{name: 'team'}"><translate>Nous rejoindre</translate></router-link></p>
            <p><router-link :to="{name: 'contact'}"><translate>Nous contacter </translate><i class="el-icon-message"></i></router-link></p>
          </div>
          <div class="three columns footer-suivre">
            <img class="footer-logo" src="../assets/Ielo_Astronaute_Blanc.png"/>
            <div class="footer-social">
              <a href="https://twitter.com/ielo_group" target="_blank" rel="noopener">
                <img src="../assets/brands/twitter.png"/>
              </a>
              <a href="https://www.linkedin.com/company/liazo/" target="_blank" rel="noopener">
                <img src="../assets/brands/linkedin.png"/>
              </a>
            </div>
          </div>
        </div>
        <p class="footer-end">© ielo. <translate>Tous droits réservés</translate></p>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
  name: 'Footer'
  }
</script>

<style>
  .footer-logo {
    max-width: 20rem;
    padding: 0px;
  }

  .footer-social {
    margin-top: 20px;
  }

  .footer-social a {
    display: inline-block;
    text-decoration: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: #ffffff;
    margin-right: 20px;
  }
</style>






