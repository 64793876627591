<template>
  <div>
    <!-- Banner network(home) –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <div class="banners">
      <div class="container">
        <div class="row">
          <Navbar></Navbar>

          <div class="section seven columns banner-text">
            <h2 class="banners-heading"><translate>Réseau optique de dernière génération</translate></h2>
            <p>── <translate>Une performance optimale et une capillarité nationale</translate></p>

            <BreakingNews></BreakingNews>
          </div>
        </div>
      </div>
    </div>

    <!-- network –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <NetworkDescription :notice="true"></NetworkDescription>
    <div class="notre-reseau">
      <NetworkCarte></NetworkCarte>
    </div>

    <!-- number –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <Number></Number>

    <!-- footer –––––––––––––––––––––––––––––––––––––––––––––––––– -->
    <Footer></Footer>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Footer from '../components/Footer'
  import NetworkDescription from '../components/NetworkDescription'
  import NetworkCarte from '../components/NetworkCarte'
  import Number from '../components/Number'
  import BreakingNews from '../components/BreakingNews'

  export default {
    name: 'Propos',
    components: {Navbar, Footer, NetworkDescription, NetworkCarte, Number, BreakingNews},
    data() {
      return {
        activeName: ['1']
      };
    }
  }
</script>

<style>
  .news-right-side p {
    color: #B0B0B0;
    font-size: 15px;
  }

</style>






