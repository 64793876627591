<template>
  <header class="navbar">
    <div class="two columns">
      <div class="logo">
        <router-link :to="{name: 'home'}"><img :src="img_src_logo" alt="ielo"/></router-link>
      </div>
    </div>
    <nav class="left">
      <ul class="navbar-list">
        <li class="navbar-item">
          <router-link class="navbar-link" :to="{name: 'news'}"><translate>News</translate></router-link>
        </li>
        <li class="navbar-item">
          <router-link class="navbar-link" :to="{name: 'network'}"><translate>Notre réseau</translate></router-link>
        </li>
        <li class="navbar-item">
          <router-link class="navbar-link" :to="{name: 'offers'}"><translate>Nos offres opérateurs</translate></router-link>
        </li>
        <li class="navbar-item" v-if="language === 'fr'">
          <router-link class="navbar-link" :to="{name: 'community'}"><translate>Collectivités</translate></router-link>
        </li>
        <li class="navbar-item">
          <router-link class="navbar-link" :to="{name: 'team'}"><translate>Nous rejoindre</translate></router-link>
        </li>
      </ul>
    </nav>
    <nav class="">
      <ul class="navbar-list">
        <li class="navbar-item">
          <a class="navbar-link" href="https://portal.ielo-liazo.com/" target="_blank" rel="noopener"><translate>Espace Client</translate></a>
        </li>
        <!--todo désactiver pour l'instant-->
        <li class="navbar-item">
          <LanguageDropdown class="navbar-link"></LanguageDropdown>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
  import img_src_logo from "../assets/logo.png"
  import img_src_logo_blue from "../assets/logo_Bleu.png"
  import LanguageDropdown from "../components/LanguageDropdown.vue"

  export default {
    name: "Banner",
    components: {
      LanguageDropdown
    },
    props: ['page'],
    data () {
      return {
        img_src_logo: img_src_logo,
        language: window.localStorage.getItem('language') || 'fr'
      }
    },
    mounted () {
      if(this.page === 'team' || this.page === 'news'){
        this.img_src_logo = img_src_logo_blue
      }
    }
  }
</script>

<style>
  .navbar .navbar-list {
    list-style: none;
  }

  .navbar-link {
    text-decoration: none;
    font-size: 12px;
  }

  .navbar .right {
    float: right;
  }

  .navbar .right .navbar-link {
    text-align: right;
  }

  .navbar-item .router-link-active {
    color: #01B1AD;
  }

  .navbar-link:hover {
    color: #01B1AD;
  }

  .navbar-item .navbar-link-btn {
    display: inline;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
